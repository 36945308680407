.table-carriers {
  width: 100%;
  table-layout: fixed;
  margin: 0 0 30px;

  td {
    background: #fff;
    border: 5px solid #f9f9fb;
    padding: 5px 10px;
  }
}

.label-auth {
  font-size: 12px;
  margin-right: 10px;
}

.section-block {
  img {
    max-width: 100%;
  }
}